<template>
    <div v-if="$store.state.beforerouter">
        <transition mode="out-in" name="bar">
            <div class="diybar" v-if="!diybarData.position" @click.stop :style="'width:'+(diybarData.count+1)*50+'px'">
                <div class="diybarinner">
                    <div class="diypoint" v-for="(v,i) in diybarData.count" :key="i" @click="handletarget(i)"></div>
                    <div class="targetdiypoint" :style="'left:'+(diybarData.targetleft+30*2*diybarData.target)+'px'"></div>
                </div>
            </div>
        </transition>
        <transition mode="out-in" name="bar">
            <div class="tdiybar"  v-if="diybarData.position" @click.stop :style="'height:'+(diybarData.count+1)*50+'px'">
                <div class="tdiybarinner">
                    <div class="tdiypoint" v-for="(v,i) in diybarData.count" :key="i" @click="handletarget(i)"></div>
                    <div class="ttargetdiypoint" :style="'top:'+(diybarData.targetleft+30*2*diybarData.target)+'px'"></div>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
    export default {
        props:{
          diybarData: {
              default:[],
              position:{
                  //位置：0为下方，1为左方
                  type:Number,
                  default: 0
              },
              target:{
                //目标点次序
                type:Number,
                default: 0
              },
              targetleft:{
                //目标点次序
                type:Number,
                default: 0
              },

          },
        },
        created() {
            this.$store.state.beforerouter = true;
        },
        methods:{
            handletarget(e){
                this.$emit('handlediybar',{target:e})
            },
        },
    }
</script>

<style scoped>
    .diybar{
        position: absolute;
        left: 50%;
        bottom: 5%;
        transform: translateX(-50%);
        z-index: 100;
        height: 30px;
        background: transparent;
        display: flex;
        flex-direction: row;
        align-items: center;
        transition: 0.3s;
        /*animation: change 1s forwards;*/
        /*opacity: 0;*/
    }
    /*@keyframes change {*/
    /*    0%{*/
    /*        opacity: 0;*/
    /*    }*/
    /*    100%{*/
    /*        opacity: 1;*/
    /*    }*/
    /*}*/
    .diypoint{
        width: 20px;
        height: 20px;
        border-radius: 50%;
        background: rgba(255,255,255,0.3);
        margin-left: 40px;
        cursor: pointer;
        transition: 0.3s;
    }
    .diypoint:first-of-type{
        margin-left: 0;
    }
    .diybar:hover .diypoint,.diybar:hover .diybarinner,.tdiybar:hover .tdiypoint,.tdiybar:hover .tdiybarinner {
        background: white;
    }
    .diybar:hover .targetdiypoint,.tdiybar:hover .ttargetdiypoint{
        border: 1px solid white;
    }
    .targetdiypoint{
        height: 30px;
        width: 30px;
        border-radius: 50%;
        background: transparent;
        border: 1px solid rgba(255,255,255,0.3);
        position: absolute;
        transform: translateX(-50%) translateY(-50%);
        top: 1px;
        transition: 0.3s;
        cursor: pointer;
    }
    .diybarinner{
        width: 100%;
        height: 2px;
        background: rgba(255,255,255,0.3);
        display: flex;
        flex-direction: row;
        align-items: center;
        transition: 0.3s;
        position: absolute;
        justify-content: center;
    }
    .tdiybar{
        position: absolute;
        right: 3%;
        top: 50%;
        transform: translateY(-50%);
        z-index: 100;
        width: 30px;
        background: transparent;
        display: flex;
        flex-direction: column;
        align-items: center;
        transition: 0.3s;
        /*animation: change 1s forwards;*/
        /*opacity: 0;*/
    }
    .tdiypoint{
        width: 20px;
        height: 20px;
        border-radius: 50%;
        background: rgba(255,255,255,0.3);
        margin-top: 40px;
        cursor: pointer;
        transition: 0.3s;
    }
    .tdiypoint:first-of-type{
        margin-top: 0;
    }
    .ttargetdiypoint{
        height: 30px;
        width: 30px;
        border-radius: 50%;
        background: transparent;
        border: 1px solid rgba(255,255,255,0.3);
        position: absolute;
        transform: translateX(-50%) translateY(-50%);
        left: 1px;
        transition: 0.3s;
        cursor: pointer;
    }
    .tdiybarinner{
        width: 2px;
        height: 100%;
        background: rgba(255,255,255,0.3);
        display: flex;
        flex-direction: column;
        align-items: center;
        transition: 0.3s;
        position: absolute;
        justify-content: center;
    }
    .bar-enter-active,.bar-leave-active{
        transition: 0.5s;
    }
    .bar-enter-from{
        opacity: 0;
    }

    .bar-leave-to{
        opacity: 0;
    }

</style>